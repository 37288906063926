<template>
  <div class="fbxq">
    <!-- <el-button type="primary" @click="handleClickAdd">开始合作</el-button> -->
    <full-page
      ref="fullpage"
      :options="options"
    >
      <div class="section">
        <div class="page page1">
          <Header
            :fbxq-log-show="fbxqLogShow"
            :is-last="isLast"
          />
          <img
            src="../img/fbxq/fbxq-title.png"
            alt=""
            class="fbxq-title"
          >
          <div class="fbxq-desc">
            快速拥有自己的任务平台，且在不影响现有收益的情况下，多增加一份收益；用激励的方式激活沉默用户以及流失的用户，一起联运变现。
          </div>
          <div class="fbxq-info">
            <div class="info-qrcode">
              <img
                src="../img/fbxq/fbCode.png"
                alt=""
              >
            </div>
            <div class="info-text">
              <div>联运系统</div>
              <div>服务器环境：Win2008&Win2012&Linux</div>
              <div>软件环境：LNMP/LAMP</div>
              <div>大小：70M</div>
              <div>版本：fenbei1.0</div>
              <div>著作权号：第0954100号</div>
            </div>
          </div>
          <div
            class="fbxq-join"
            @click="handleClickAdd"
          >
            立即合作
          </div>
          <img
            src="../img/fbxq/fbxq-icon.png"
            alt=""
            class="fbxq-icon"
          >
        </div>
      </div>
      <div class="section">
        <div class="page page2">
          <div class="page-title">
            <div>0成本 高收益 持久稳定</div>
            <img
              class="page-title-icon"
              src="../img/fbxq/title-icon.png"
              alt=""
            >
          </div>
          <div class="page2-card">
            <div class="page2-card-item">
              <img
                src="../img/fbxq/card1.png"
                alt=""
                class="card-item-icon"
              >
              <div class="card-item-info">
                <div class="card-item-title">
                  流量为王 快速变现 <span>ARPU超10元+</span>
                </div>
                <div class="card-item-desc">
                  通过现有的广告资源，助力公司快速生长
                </div>
              </div>
            </div>
            <div class="page2-card-item">
              <img
                src="../img/fbxq/card2.png"
                alt=""
                class="card-item-icon"
              >
              <div class="card-item-info">
                <div class="card-item-title">
                  快速适应行业发展
                </div>
                <div class="card-item-desc">
                  100多个版本迭代，深挖用户特点，快速
                </div>
              </div>
            </div>
            <div class="page2-card-item">
              <img
                src="../img/fbxq/card3.png"
                alt=""
                class="card-item-icon"
              >
              <div class="card-item-info">
                <div class="card-item-title">
                  免除100%广告对接烦恼
                </div>
                <div class="card-item-desc">
                  全方位运营支持，省去技术烦恼，实现广 告
                </div>
              </div>
            </div>
            <div class="page2-card-item">
              <img
                src="../img/fbxq/card4.png"
                alt=""
                class="card-item-icon"
              >
              <div class="card-item-info">
                <div class="card-item-title">
                  高级运营用户
                </div>
                <div class="card-item-desc">
                  精准化运营 单个用户ARUP值25+
                </div>
              </div>
            </div>
          </div>
          <div class="page2-data">
            <div class="data-icon" />
            <div class="data-title">
              业务数据图
            </div>
            <img
              class="data-img"
              src="../img/fbxq/page2-data.png"
              alt=""
            >
          </div>
          <div
            class="fbxq-join"
            @click="handleClickAdd"
          >
            立即合作
          </div>
        </div>
      </div>
      <div class="section">
        <div class="page page3">
          <div class="page-title white">
            <div>我们的优势</div>
            <img
              class="page-title-icon"
              src="../img/fbxq/title-icon1.png"
              alt=""
            >
          </div>
          <div class="page3-desc">
            无微不至的闭环服务，专业级服务团队保障产品安全可靠
          </div>
          <div class="page3-card">
            <div
              v-for="(item,index) in page3CardList"
              :key="index"
              class="card-item"
            >
              <img
                :src="item.src"
                class="card-item-icon"
                alt=""
              >
              <div class="card-item-info">
                <div class="card-item-title">
                  {{ item.title }}
                </div>
                <div class="card-item-desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="fbxq-join"
            @click="handleClickAdd"
          >
            立即合作
          </div>
        </div>
      </div>
      <div class="section">
        <div class="page page4">
          <div class="page-title">
            <div>产品功能与演示</div>
            <img
              class="page-title-icon"
              src="../img/fbxq/title-icon.png"
              alt=""
            >
          </div>
          <div class="page4-desc">
            兼具高安全、高性能、低成本、易运维、全功能的优势、服务部署模式，助力产业升级
          </div>
          <div class="page4-show">
            <div class="show-left">
              <img
                src="../img/fbxq/show-bg.png"
                alt=""
                class="show-left-bg"
              >
              <img
                src="../img/fbxq/show-title.png"
                alt=""
                class="show-left-title"
              >
              <img
                :src="showImgSrc"
                alt=""
                class="show-left-img"
              >
            </div>
            <div class="show-right">
              <div class="show-right-list">
                <img
                  src="../img/fbxq/show5.png"
                  alt=""
                >
                <img
                  src="../img/fbxq/show6.png"
                  alt=""
                >
                <img
                  src="../img/fbxq/show7.png"
                  alt=""
                >
                <img
                  src="../img/fbxq/show8.png"
                  alt=""
                >
              </div>
              <div class="show-right-desc">
                主要功能：新人红包、游戏试玩、小游戏试玩、应用试玩、赚钱榜单、每日任务、进行中任务、VIP会员、提现、赚钱攻略
              </div>
              <div class="show-right-sign">
                新人红包、VIP、免费提现、赚钱榜单等运营策略，大大提升用户转化，单个用户ARUP值25+
              </div>
              <div class="show-right-btns">
                <div
                  class="show-right-btn"
                  @click="downLoadApp"
                >
                  下载应用
                </div>
                <div
                  class="show-right-btn"
                  @click="showMoreActive=true"
                >
                  了解更多
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="page page5">
          <div class="page-title">
            <div>只需3步，即可轻松得收益</div>
            <img
              class="page-title-icon"
              src="../img/fbxq/title-icon.png"
              alt=""
            >
          </div>
          <div class="page5-desc">
            0成本 10分钟内搞定
          </div>
          <div class="page5-list">
            <div class="page5-item">
              <img
                class="page5-item-img"
                src="../img/fbxq/step1.png"
                alt=""
              >
              <div class="page5-item-text">
                开始合作
              </div>
            </div>
            <img
              class="page5-icon"
              src="../img/fbxq/page5-icon.png"
              alt=""
            >
            <div class="page5-item">
              <img
                class="page5-item-img"
                src="../img/fbxq/step2.png"
                alt=""
              >
              <div class="page5-item-text">
                获得推广链接
              </div>
            </div>
            <img
              class="page5-icon"
              src="../img/fbxq/page5-icon.png"
              alt=""
            >
            <div class="page5-item">
              <img
                class="page5-item-img"
                src="../img/fbxq/step3.png"
                alt=""
              >
              <div class="page5-item-text">
                投放推广
              </div>
            </div>
            <img
              class="page5-icon"
              src="../img/fbxq/page5-icon.png"
              alt=""
            >
            <div class="page5-item">
              <img
                class="page5-item-img"
                src="../img/fbxq/step4.png"
                alt=""
              >
              <div class="page5-item-text">
                获得收益
              </div>
            </div>
          </div>
          <div
            class="fbxq-join"
            @click="handleClickAdd"
          >
            立即合作
          </div>
        </div>
      </div>
    </full-page>
    <!-- <Add v-if="showAdd" @handleClickHide="handleClickHide" @addSuccess="addSuccess" />
    <ShowMore v-if="showMoreActive" @handleClickHideMore="handleClickHideMore" /> -->
  </div>
</template>

<script>
// import Add from '../components/add'
// import ShowMore from '../components/showMore'
import Header from "@/components/header";
import { fbxqAppInfo } from '@/api'
export default {
  components: {
    Header
    // Add,
    // ShowMore
  },
  data() {
    return {
      appInfo: {},
      showMoreActive: false,
      fbxqLogShow:true,
      options: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        scrollOverflow: false,
        scrollBar: false,
        menu: '#menu'
      },
      showAdd: false,
      page3CardList: [
        { src: require('../img/fbxq/icon1.png'), title: '100%自研开发', desc: '可以根据不同的需求进行定制' },
        { src: require('../img/fbxq/icon2.png'), title: '原生APP开发', desc: '安卓+IOS原生盒子APP，流畅用户 体验才是王道' },
        { src: require('../img/fbxq/icon3.png'), title: '提供更新升级服务', desc: '深挖用户特点，快速提高用户转化' },
        { src: require('../img/fbxq/icon4.png'), title: '支持定制开发', desc: '满足您的需求，项目进度透明，敏捷开发，闭环服务' },
        { src: require('../img/fbxq/icon5.png'), title: '专业团队服务', desc: '7*12全天为您提供贴心服务' },
        { src: require('../img/fbxq/icon6.png'), title: '系统安全稳定', desc: '加固强度高，有效对抗多种反编译 逆向工具，防止APP被破解剽窃' }
      ],
      showImgSrc: '',
      showImgActive: 5,
      isLast: false
    }
  },
  created() {
    this.showImgSrc = require(`../img/fbxq/show-bg${this.showImgActive}.png`)
    this.getfbxqAppInfo()
  },
  methods: {
    downLoadApp() {
      window.open('https://android.zhuoyixia.com/fbxq_1.0.5_prod_2_1.apk')
      // window.open(this.appInfo.channelApkUrl)
    },
    async getfbxqAppInfo() {
      const res = await fbxqAppInfo()
      this.appInfo = res.data
    },
    handleClickHideMore() {
      this.showMoreActive = false
    },
    handleClickHide() {
      this.showAdd = false
    },
    addSuccess() {
      this.showAdd = false
      this.$store.commit('settings/CHANGE_SHOWFBXQ', true)
      this.$router.push('/fbxq/main')
    },
    handleClickAdd() {
      // this.showAdd = true
      this.$message('请联系媒介对接人！')
    }
  }
}
</script>

<style lang="scss" scoped>
.page{
  position: relative;
  height: 100%;
}
.page1{
  padding-top: 88px;
  padding-left: 267px;
  background-image: url('../img/fbxq/page-bg.png');
  background-size: cover;
  /deep/ .menu{
    .logo{
      margin-left: 0;
    }
    .header-right{
      margin-left: 60px;
    }
  }
}
.fbxq-title{
  width: 909px;
  height: 96px;
  margin-bottom: 50px;
  margin-top: 80px;
}
.fbxq-desc{
  position: relative;
  z-index: 3;
  width: 1347px;
  font-size:30px;
  font-weight:300;
  color:rgba(255,255,255,1);
  line-height:42px;
  margin-bottom: 84px;
}
.fbxq-info{
  display: flex;
  align-items: center;
  margin-bottom: 81px;
  .info-qrcode{
    position: relative;
    width: 190px;
    height: 235px;
    margin-right: 35px;
    background-image: url('../img/fbxq/download-bg.png');
    background-size: cover;
    img{
      width: 180px;
      height: 180px;
      border-radius:10px;
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .info-text{
    font-size:24px;
    font-weight:300;
    color:rgba(255,255,255,1);
    line-height:36px;
    letter-spacing:2px;
  }
}
.fbxq-join{
  cursor: pointer;
  width:682px;
  height:106px;
  background:rgba(174,43,255,1);
  border-radius:5px;
  text-align: center;
  line-height: 106px;
  font-size:40px;
  font-weight:600;
  color:rgba(255,255,255,1);
}
.fbxq-icon{
  position: absolute;
  bottom: 7px;
  right: 162px;
  width: 785px;
  height: 734px;
}
/////////////////  page2
.page2{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 260px;
  background-image: url('../img/fbxq/page2-bg.png');
  background-size: cover;
}
.page-title{
  position: relative;
  font-size:54px;
  font-weight:500;
  color:rgba(51,51,51,1);
  line-height:75px;
  margin-top: 45px;
  &.white{
    color:#fff;
  }
  &-icon{
    position: absolute;
    width: 60px;
    height: 60px;
    top: -18px;
    right: -16px;
  }
}
.page2-card{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  &-item{
    display: flex;
    width:605px;
    height:134px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 10px 0px rgba(172,156,255,0.12);
    border-radius:10px;
    box-sizing: border-box;
    padding-right: 40px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  .card-item-icon{
    width: 81px;
    height: 81px;
    margin-left: 33px;
    margin-top: 18px;
    margin-right: 33px;
  }
  .card-item-info{
    margin-top: 18px;
  }
  .card-item-title{
    font-size:30px;
    font-weight:600;
    color:rgba(51,51,51,1);
    line-height:32px;
    margin-bottom: 10px;
    span{
      color: #EC7A7A;
    }
  }
  .card-item-desc{
    font-size:22px;
    font-weight:300;
    color:rgba(34,34,34,1);
    line-height:30px;
  }
}
.page2-data{
  position: relative;
  width:1398px;
  height:304px;
  background:rgba(255,255,255,1);
  box-shadow:0px 2px 10px 0px rgba(172,156,255,0.12);
  border-radius:10px;
  box-sizing: border-box;
  padding-left: 32px;
  padding-top: 19px;
  margin-bottom: 20px;
  .data-icon{
    display: inline-block;
    width:4px;
    height:18px;
    background:rgba(131,86,255,1);
    margin-right: 9px;
  }
  .data-title{
    display: inline-block;
    font-size:18px;
    color:rgba(51,51,51,1);
    line-height:25px;
  }
  .data-img{
    width: 1042px;
    height: 263px;
    position: absolute;
    top: 19px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
////////////page3
.page3{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../img/fbxq/page-bg.png');
  background-size: cover;
}
.page3-desc{
  font-size:30px;
  font-weight:300;
  color:rgba(255,255,255,1);
  line-height:42px;
  margin-bottom: 60px;
  margin-top: 60px;
}
.page3-card{
  width: 100%;
  box-sizing: border-box;
  padding: 0 212px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  .card-item{
    display: flex;
    align-items: center;
    width:460px;
    height:175px;
    background:rgba(255,255,255,1);
    box-shadow:0px 4px 10px 0px rgba(40,0,162,0.25);
    border-radius:3px;
    margin-bottom: 43px;
    &-icon{
      width: 100px;
      height: 100px;
      margin: 0 20px;
      flex: none;
    }
    &-title{
      font-size:30px;
      font-weight:500;
      color:rgba(51,51,51,1);
      line-height:42px;
      margin-bottom: 5px;
    }
    &-desc{
      font-size:20px;
      font-weight:300;
      color:rgba(34,34,34,1);
      line-height:28px;
    }
  }
}
////////////page4
.page4{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../img/fbxq/page4-bg.png');
  background-size: cover;
}
.page4-desc{
  font-size:30px;
  font-weight:300;
  color:rgba(51,51,51,1);
  line-height:42px;
  margin-top: 22px;
  margin-bottom: 28px;
}
.page4-show{
  display: flex;
  align-items: center;
  .show-left{
    position: relative;
    width: 350px;
    height: 675px;
    margin-right: 169px;
  }
  .show-left-bg{
    position: absolute;
    width: 350px;
    height: 675px;
  }
  .show-left-title{
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 25px;
    margin: 0 auto;
    width: 196px;
    height: 20px;
  }
  .show-left-img{
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 25px;
    margin: 0 auto;
    width: 290px;
    height: 623px;
  }
}
.show-right-list{
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  img{
    width: 173px;
    height: 308px;
    margin-right: 60px;
  }
}
.show-right-desc{
  width: 879px;
  font-size:28px;
  font-weight:400;
  color:rgba(102,102,102,1);
  line-height:40px;
}
.show-right-sign{
  width: 879px;
  font-size:28px;
  font-weight:400;
  color:rgba(236,122,122,1);
  line-height:40px;
  margin-bottom: 30px;
}
.show-right-btns{
  display: flex;
  align-items: center;
  .show-right-btn{
    cursor: pointer;
    width: 332px;
    height: 106px;
    background:rgba(174,43,255,1);
    border-radius:5px;
    text-align: center;
    line-height: 106px;
    color: #fff;
    font-size:40px;
    font-weight:400;
  }
  .show-right-btn:nth-child(1){
    margin-right: 154px;
  }
}
///////////////page4
.page5{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../img/fbxq/page5-bg.png');
  background-size: cover;
}
.page5-desc{
  font-size:30px;
  font-weight:300;
  color:rgba(102,102,102,1);
  line-height:42px;
  margin-top: 40px;
  margin-bottom: 104px;
}
.page5-list{
  display: flex;
  align-items: center;
  margin-bottom: 174px;
  .page5-item{
    position: relative;
    width: 200px;
    height: 200px;
    &-img{
      width: 100%;
      height: 100%;
    }
    &-text{
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size:32px;
      bottom: -50px;
      font-weight:300;
      color:rgba(51,51,51,1);
      line-height:45px;
      text-align: center;
    }
  }
  .page5-icon{
    width: 69px;
    height: 22px;
    margin: 0 66px;
  }
}
@media screen and (max-width: 1440px) {
  .page1{
    padding-top: 51px;
    padding-left: 200px;
    padding-bottom: 116px;
  }
  .fbxq-title{
    width: 682px;
    height: 72px;
    margin-bottom: 42px;
  }
  .fbxq-desc{
    width: 1011px;
    font-size:23px;
    line-height:32px;
    margin-bottom: 63px;
  }
  .fbxq-info{
    margin-bottom: 60px;
    .info-qrcode{
      width: 144px;
      height: 177px;
      margin-right: 30px;
      img{
        width: 135px;
        height: 135px;
        border-radius:8px;
        top: 3px;
      }
    }
    .info-text{
      font-size:18px;
      line-height:27px;
      letter-spacing:1px;
    }
  }
  .fbxq-join{
    width:512px;
    height:80px;
    background:rgba(174,43,255,1);
    border-radius:4px;
    line-height: 80px;
    font-size:30px;
  }
  .fbxq-icon{
    bottom: 5px;
    right: 121px;
    width: 590px;
    height: 551px;
  }
  /////////////////  page2
  .page2{
    padding: 0 195px 37px;
  }
  .page-title{
    font-size:41px;
    line-height:57px;
    margin-top: 30px;
    &-icon{
      width: 46px;
      height: 46px;
      top: -14px;
      right: -13px;
    }
  }
  .page2-card{
    margin-top: 17px;
    &-item{
      width:454px;
      height:101px;
      background:rgba(255,255,255,1);
      box-shadow:0px 2px 8px 0px rgba(172,156,255,0.12);
      border-radius:8px;
      margin-bottom: 15px;
    }
    .card-item-icon{
      width: 61px;
      height: 61px;
      margin-left: 14px;
      margin-top: 14px;
      margin-right: 25px;
    }
    .card-item-info{
      margin-top: 24px;
    }
    .card-item-title{
      font-size:23px;
      line-height:24px;
      margin-bottom: 8px;
      span{
        color: #EC7A7A;
      }
    }
    .card-item-desc{
      font-size:17px;
      line-height:23px;
    }
  }
  .page2-data{
    flex: none;
    width:1049px;
    height:228px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 8px 0px rgba(172,156,255,0.12);
    border-radius:8px;
    padding-left: 24px;
    padding-top: 14px;
    margin-bottom: 14px;
    .data-icon{
      width:3px;
      height:14px;
      background:rgba(131,86,255,1);
      margin-right: 7px;
    }
    .data-title{
      font-size:14px;
      line-height:19px;
    }
    .data-img{
      width: 781px;
      height: 197px;
      top: 14px;
    }
  }
  ////////////page3
  .page3{
    padding-bottom: 42px;
  }
  .page3-desc{
    font-size:24px;
    line-height:33px;
    margin-bottom: 46px;
    margin-top: 44px;
  }
  .page3-card{
    padding: 0 159px;
    margin-bottom: 37px;
    .card-item{
      width:345px;
      height:131px;
      background:rgba(255,255,255,1);
      box-shadow:0px 3px 8px 0px rgba(40,0,162,0.25);
      border-radius:2px;
      margin-bottom: 33px;
      &-icon{
        width: 75px;
        height: 75px;
        margin: 0 15px;
      }
      &-title{
        font-size:23px;
        line-height:32px;
        margin-bottom: 3px;
      }
      &-desc{
        font-size:14px;
        line-height:21px;
      }
    }
  }
  ////////////page4
  .page4{
    padding-bottom: 42px;
  }
  .page4-desc{
    font-size:23px;
    font-weight:300;
    color:rgba(51,51,51,1);
    line-height:33px;
    margin-top: 16px;
    margin-bottom: 20px;
  }
  .page4-show{
    display: flex;
    align-items: center;
    .show-left{
      position: relative;
      width: 263px;
      height: 507px;
      margin-right: 126px;
    }
    .show-left-bg{
      position: absolute;
      width: 263px;
      height: 507px;
    }
    .show-left-title{
      top: 19px;
      width: 148px;
      height: 16px;
    }
    .show-left-img{
      top: 19px;
      width: 218px;
      height: 468px;
    }
  }
  .show-right-list{
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    img{
      width: 130px;
      height: 231px;
      margin-right: 45px;
    }
  }
  .show-right-desc{
    width: 659px;
    font-size:21px;
    line-height:29px;
    margin-bottom: 17px;
  }
  .show-right-sign{
    width: 659px;
    font-size:21px;
    line-height:29px;
    margin-bottom: 25px;
  }
  .show-right-btns{
    display: flex;
    align-items: center;
    .show-right-btn{
      width:249px;
      height:80px;
      background:rgba(174,43,255,1);
      border-radius:4px;
      line-height: 80px;
      font-size:30px;
    }
    .show-right-btn:nth-child(1){
      margin-right: 116px;
    }
  }
  ///////////////page5
  .page5-desc{
    font-size:23px;
    line-height:32px;
    margin-top: 14px;
    margin-bottom: 77px;
  }
  .page5-list{
    margin-bottom: 131px;
    .page5-item{
      position: relative;
      width: 150px;
      height: 150px;
      &-img{
        width: 100%;
        height: 100%;
      }
      &-text{
        position: absolute;
        left: 0;
        right: 0;
        bottom: -47px;
        margin: 0 auto;
        font-size:24px;
        font-weight:300;
        color:rgba(51,51,51,1);
        line-height:33px;
      }
    }
    .page5-icon{
      width: 52px;
      height: 17px;
      margin: 0 49px;
    }
  }
}
</style>
